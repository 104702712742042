import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import Breadcrumb from "../components/breadcrumb"
import SEO from "../components/seo"

export default function Destination({ data, pageContext }) {
    const location = data.listingdata
    const name = location.frontmatter.title
    const seotitle = `${name} Staycations`
    let crumbdata = [ [location.frontmatter.region,"destinations/"+location.frontmatter.region.toLowerCase()],[location.frontmatter.town,"destinations/"+location.frontmatter.town.toLowerCase()],`${name}`]
  return (
    <Layout>
        <SEO title={seotitle} description="Never been beyond your house and the airport? Don't worry we've got you sorted." />
        <Breadcrumb crumbs={ crumbdata } />
        <Img
          fluid={location.keyimage.childImageSharp.fluid}
        />
        <h1>{name}</h1>
        <div><strong>{location.frontmatter.pricing}</strong> {location.frontmatter.pricingdetail}<br/>&nbsp;</div>
      <div dangerouslySetInnerHTML={{ __html: location.html }}></div>
    </Layout>
  )
}


export const query = graphql`
  query($slug: String!) {
    listingdata: markdownRemark(frontmatter: {type: {eq: "listing"}}, fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        town
        region
        pricing
        pricingdetail
      }
      keyimage {
        childImageSharp {
          fluid(maxWidth: 925, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`